import {ILocationService, ILogService, IRootScopeService, IWindowService} from "angular";
import {IProperties, IRootScope} from "../hpc";

declare const TRACKERID;


export class TrackerService {

    public static $inject = ['$rootScope', '$window', '$location', '$log', 'properties'];

    constructor(private $rs: IRootScope, private $window: IWindowService, private $location: ILocationService, private $log: ILogService, private properties: IProperties) {
        if (this.isTrackerActive()) {
            $log.warn("Tracker initialized");
            $window._paq.push(['setSiteId', TRACKERID]);
        } else {
            $log.warn("Running in Debug mode. Tracker disabled");
        }

        $rs.$on('$locationChangeSuccess', () => this.trackPageView());
        this.trackPageView();
    }

    public trackPageView() {
        if (this.isTrackerActive()) {
            this.$window._paq.push(['setDocumentTitle', this.$location.path()]);
            this.$window._paq.push(['setCustomUrl', this.$location.url()]);
            this.$window._paq.push(['trackPageView']);
            this.$log.info('tracking PageView: ' + this.$location.path());
        }
    }

    public trackEvent(category, action, name?, value?) {
        if (this.isTrackerActive()) {
            this.$window._paq.push(['trackEvent', category, action, name, value])
            this.$log.info('tracking Event: ' + name);

        }
    }

    public setCustomVariable(index: number, name: string, value: string, scope = 'visit') {
        if (this.isTrackerActive()) {
            this.$window._paq.push(['setCustomVariable', index, name, value, scope]);
        }
    }

    public setUserId(username) {
        if (this.isTrackerActive()) {
            this.$window._paq.push(['setUserId', username]);
        }
    }

    private isTrackerActive() {
        return this.$window._paq && this.properties.debugMode != 1 && TRACKERID > 0;
    }
}