'use strict';

import {app} from '../app';
import {TrackerService} from "./TrackerService";
import {StyleManagerService} from "./StyleManagerService";
import {LanguageSwitchService} from "./LanguageSwitchService";
import {MandatorService} from './MandatorService';

declare const DEBUGMANDATOR;
declare const DEBUGRESTURL;
declare const DEBUGASSETBASEURL;
declare const DEBUGMODE;
declare const OAUTHURLBASEURL;
declare const APPVERSION;

/* Services */
app
    .value('ui_version', APPVERSION)
    .factory('properties', ['$window', function ($window) {

        var config = {
            debugMode: DEBUGMODE,
            debugLogin: '',
            debugPassword: '',
            debugMandator: DEBUGMANDATOR,
            debugRestBaseUrl: DEBUGRESTURL,
            debugAssetsBaseUrl: DEBUGASSETBASEURL,
            restBaseUrl: '/services/rest/v2/',
            assetsBaseUrl: '',
            language: 'de',
            oauthBaseUrl: OAUTHURLBASEURL,
            adminTokenAuthBaseUrl: '/user/tokenAuth'
        }

        //add external injected config
        if ($window.evmConfig) {
            for (let key in $window.evmConfig) {
                config[key] = $window.evmConfig[key];
            }
        }

        return config;
    }])
    .factory('audio', ['$document', function ($document) {
        var audio = $document[0].createElement('audio');
        audio.volume = 0.8;
        audio.autoplay = false;
        return audio;
    }])


    .service('languageSwitch', LanguageSwitchService)
    .service('tracker', TrackerService)
    .service('styleManager', StyleManagerService)
    .service('mandatorService', MandatorService)






