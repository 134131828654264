import * as restangular from 'restangular';
import {IAppModel, IMandator, IMandatorLayoutProperty, IProperties, IRootScope} from '../hpc';
import {IHttpPromise, IHttpService, ILocationService, ILogService} from 'angular';
import {TrackerService} from './TrackerService';
import {StyleManagerService} from './StyleManagerService';
import {LanguageSwitchService} from './LanguageSwitchService';


export class MandatorService {

    public static $inject = ['$sessionStorage', '$log', 'tracker', 'styleManager', '$rootScope', 'properties', '$http', '$location', 'languageSwitch'];

    private mandator: IHttpPromise<IMandator>;
    private mandatorName: string;

    constructor(
        private appModel: IAppModel,
        private $log: ILogService,
        private tracker: TrackerService,
        private styleManager: StyleManagerService,
        private $rs: IRootScope,
        private properties: IProperties,
        private $http: IHttpService,
        private $location: ILocationService,
        private languageSwitch: LanguageSwitchService
    ) {
        this.mandatorName = appModel.mandatorName = $location.host().split('.')[0];
        this.getMandator();
    }


    getMandator() {
        if (this.mandator) {
            return this.mandator;
        }
        this.mandator = this.$http.get("/services/rest/v2/mandators/unblocked/" + this.mandatorName + ".json");
        var that = this;


        this.mandator.then(function (res) {
            that.$log.info('getMandator success');
            that.appModel.mandator = res.data;
            that.$rs.layouts = that.appModel.layouts = that.extractLayoutProperties(res.data.mandatorLayoutProperties);
            //that.languageSwitch.extendLanguages(JSON.parse(that.appModel.layouts.TRANSLATIONS))
            that.tracker.setCustomVariable(1, 'mandator', that.appModel.mandatorName, 'visit')
            that.styleManager.initCssVariables(res.data.mandatorLayoutProperties);
            that.languageSwitch.selectMandatorLanguage();

        }, function (res: restangular.IResponse) {
            that.$rs.errormessage = res.data.type;
            that.$log.error("get Mandators Fail");
            alert("Service not available. Please verify URL or try again later.")
        })
        return this.mandator;
    }


    /**
     * Extract and transform LayoutProperties to layout object
     *
     * @param list
     * @returns {{}}
     */
    extractLayoutProperties(list: IMandatorLayoutProperty[]) {
        var layouts = {};
        list.forEach((item) => {
            if (item.propertyName == 'logoMandatorImagePath' || item.propertyName == 'loginImagePath') {
                layouts[item.propertyName] = this.properties.assetsBaseUrl + item.value;
            } else if (item.value.indexOf('Path') != -1) {
                layouts[item.propertyName] = 'url(' + this.properties.assetsBaseUrl + item.value + ')';
            } else if (item.propertyName.indexOf("form")==0) {
                try {
                    layouts[item.propertyName] = JSON.parse(item.value);
                } catch (e) {
                    layouts[item.propertyName] = item.value;
                }
            } else {
                layouts[item.propertyName] = item.value;
            }
        })
        return layouts;
    }

}