import {IAppModel, IBaseScope, ILastDelivery, IMandator, IProperties, IRegisterAccount, IRootScope} from "../hpc";
import * as restangular from "restangular";
import {LanguageSwitchService} from "../services/LanguageSwitchService";
import {IHttpService, ILocationService} from "angular";


/**
 * Created with IntelliJ IDEA.
 * User: mjaeger
 * Date: 10.12.13
 * Time: 18:20
 * To change this template use File | Settings | File Templates.
 */


interface IRegisterScope extends IBaseScope {
    account: IRegisterAccount;
    changeLogin: boolean;
    layouts: any;
    mandator: IMandator;
    hasDeliveryType: (string) => boolean;
    sendVerificationSMS: () => void;
    register: () => void;
    form: any;
    sendButtonClicked: boolean;
    pinRequired: () => boolean;
    getMobilePinPattern: () => any;
    getMobileNumberPattern: () => any;
    setLanguage: () => void;
    language: null;
    lastDelivery: ILastDelivery;
    getPinHelp;
    smsSent: number;
    reformatMobileNumber: (string) => any;
    getLoginImagePath: () => string;
    registerSuccess: boolean;
    isRequired: (string) => string;
    sso: boolean;
}

export let RegisterCtrl = [
    '$scope',
    '$rootScope',
    '$sessionStorage',
    'properties',
    '$location',
    'Restangular',
    '$http',
    '$log',
    '$translate',
    'languageSwitch',
    function ($s: IRegisterScope,
              $rs: IRootScope,
              appModel: IAppModel,
              properties: IProperties,
              $location: ILocationService,
              Rest,
              $http: IHttpService,
              $log: angular.ILogService,
              $translate,
              languageSwitch: LanguageSwitchService) {

        $s.sso = $location.search().sso;
        $s.mandator = appModel.mandator;
        $s.account = initAccount();
        $s.changeLogin = false;
        $s.smsSent = 0;
        $s.layouts = appModel.layouts;

        $s.getLoginImagePath = function () {
            if (appModel.layouts.loginImagePath) {
                return appModel.layouts.loginImagePath;
            } else {
                return '/images/' + appModel.mandatorName + '/imageLogin.jpg';
            }
        }

        if (appModel.demoMode) {
            $rs.errormessage = "ERROR_NO_CHANGES_IN_DEMO";
        }

        if (!appModel.mandator.registerAccount) {
            $location.path("/login");
        }


        $s.$watch('account', function (newValue: IRegisterAccount, oldValue: IRegisterAccount) {
            $s.account.mandatorLanguage = languageSwitch.getCurrentMandatorLanguage();
            appModel.registerAccount = $s.account;
        }, true)

        function reformatNumber(number, provider) {
            let val = number?.replace(/[\D]/g, '')
            if (!val?.length) {
                return null;
            }
            if (provider.countryCode && val.indexOf(provider.countryCode) != 0) {
                if (val.indexOf("0") == 0) {
                    val = provider.countryCode + val.substr(1);
                } else {
                    val = provider.countryCode + val;
                }
            }
            return val;
        }

        $s.reformatMobileNumber = function (event) {
            if(event){
                $s.account.mobileNumber = reformatNumber(event.target.value, $s.account.mandatorProvider.provider);
            } else {
                $s.account.mobileNumber = reformatNumber($s.account.mobileNumber, $s.account.mandatorProvider.provider);
            }
        }


        $s.register = function register() {
            $s.form.$setSubmitted();
            $rs.errormessage = null;
            if (appModel.demoMode) {
                $rs.errormessage = "Die Änderungen werden im Demomodus nicht übernommen!";
                return false;
            }
            if ($s.form.$invalid) {
                $rs.errormessage = 'ERROR_CHECK_FORM';
                console.log($s.form.$error)
                return false;
            }
            if ($s.account.emailAsLogin) {
                $s.account.login = $s.account.email;
            }
            $s.account.mobileNumber = $s.account.mobileNumber?.replace("+", "");
            $s.account.mandatorLanguage = languageSwitch.getCurrentMandatorLanguage();
            $rs.showLoading = true;

            var accounts = Rest.one("accounts");
            accounts.customPOST($s.account, "/", {nameMandator: appModel.mandator.name, nameAgency: $s.account.agency?.name})
                .then(function (data: any) {
                    $log.info('Account registered: ' + data);
                    $s.account = appModel.registerAccount = null;
                    //$location.path("/login");
                    $s.registerSuccess = true;
                    $rs.showLoading = false;
                }, function (res: restangular.IResponse) {
                    //errors are handled in interceptor
                    switch (res.data.type) {
                        case 'USER_ACCOUNT_EMAIL_NOT_ALLOWED':
                            $s.form.email.$setValidity('email', false);
                            break;
                        case 'USER_ACCOUNT_ALREADY_REGISTERED':
                            if ($s.account.emailAsLogin) {
                                $s.form.email.$setValidity('email', false)
                            } else {
                                $s.form.login.$setValidity('parse', false);
                            }
                            break;
                        case 'USER_ACCOUNT_NO_MOBILNUMBER_PHONENUMBER':
                            $s.form.mobileNumber?.$setValidity('required', false)
                            $s.form.phoneNumber?.$setValidity('required', false)
                            break;
                        case 'USER_MOBILENUMBER_INVALID':
                            $s.form.mobileNumber.$setValidity('parse', false);
                            break;
                        case 'USER_PHONENUMBER_INVALID':
                            $s.form.phoneNumber.$setValidity('parse', false);
                    }
                });
        }

        $s.sendVerificationSMS = function sendVerificationSMS() {
            $rs.errormessage = null;
            $s.account.activationCode = null;
            $s.sendButtonClicked = true;
            var accounts = Rest.one("accounts/me");
            accounts.customPOST(null, 'sendMobileNumberVerificationSMS', {
                msisdn: $s.account.mobileNumber,
                mandatorLanguageId: $s.account.mandatorLanguage.id,
                mandatorProviderId: $s.account.mandatorProvider.id
            })
                .then(function (res: restangular.IResponse) {
                    $log.debug('VerificationSMS sent');
                    $s.smsSent += 1;
                    appModel.registerAccount = $s.account;
                }, function (res: restangular.IResponse) {
                    //errors are handled in interceptor
                })
        }

        $s.pinRequired = function () {
            return $s.account?.mandatorProvider?.provider.pinRequired;
        }


        $s.getPinHelp = function () {
            let key = "HELP_HANDY_DATEN_PIN_" + $s.account.mandatorProvider.provider.id;
            if (isKeyAvailable(key)) {
                return $translate.instant(key);
            } else {
                return $translate.instant("HELP_HANDY_DATEN_PIN");
            }
        }

        $s.hasDeliveryType = function (typeName) {
            return appModel.mandator.deliveryTypes.filter(dt => dt.name == typeName).length > 0;
        }

        $s.isRequired = function (key) {
            return appModel.mandator.mandatorAttributes.requiredFormFields && appModel.mandator.mandatorAttributes.requiredFormFields[key] == true ? "*" : "";
        }

        function isKeyAvailable(key) {
            return $translate.instant(key) !== key
        }



        function initAccount() {
            let params = $location.search();
            let account = {
                sex: appModel.registerAccount?.sex || {id: null, name: null, description: null},
                accountAutomaticBlocks: null,
                firstname: getAccountValue(params.firstname, appModel.registerAccount?.firstname),
                id: null,
                lastname: getAccountValue(params.lastname, appModel.registerAccount?.lastname),
                salutation: getAccountValue(params.salutation, appModel.registerAccount?.salutation),
                costCenter: getAccountValue(params.costCenter, appModel.registerAccount?.costCenter),
                mandatorProvider: appModel.registerAccount?.mandatorProvider || null,
                mandatorLanguage: languageSwitch.getCurrentMandatorLanguage(),
                mobileNumber: getAccountValue(params.mobileNumber, appModel.registerAccount?.mobileNumber),
                mobilePin: appModel.registerAccount?.mobilePin,
                phoneNumber: getAccountValue(params.phoneNumber, appModel.registerAccount?.phoneNumber),
                phonePin: appModel.registerAccount?.phonePin,
                verifiedMobileNumber: null,
                activationCode: null,
                login: getAccountValue(params.login, appModel.registerAccount?.login),
                email: getAccountValue(params.email, appModel.registerAccount?.email),
                phonetic: getAccountValue(params.phonetic, appModel.registerAccount?.phonetic),
                emailAsLogin: params.sso? params.email==params.preferred_username : true,
                agency: getAccountValue(params.agency, appModel.registerAccount?.agency),
                sub: getAccountValue(params.sub, appModel.registerAccount?.sub),
                registrationCode: getAccountValue(params.code, appModel.registerAccount?.registrationCode),
            }
            if($s.mandator.mandatorProviders.length == 1){
                account.mandatorProvider = $s.mandator.mandatorProviders[0];
                account.mobileNumber = reformatNumber(account.mobileNumber, account.mandatorProvider.provider);
            }
            return account;
        }

        function getAccountValue(paramValue, modelValue){
            return typeof paramValue === 'string' ? paramValue : modelValue
        }


    }];