import {IAppModel, IRootScope} from "../hpc";

export let LogoutCtrl = [
	'$scope',
	'$rootScope',
	'$sessionStorage',
	'$localStorage',
	'$location',
	'$cookies',
	function ($s: any,
			  $rs: IRootScope,
			  appModel: IAppModel,
			  $localStorage: any,
			  $location: ng.ILocationService,
			  $cookies: ng.cookies.ICookiesService) {

		function logout() {
			console.log('logging out');
			$cookies.remove("userLogin", {
				path: "/",
				secure: true,
				samesite: "strict"
			});
			$cookies.remove("remember", {
				path: "/",
				secure: true,
				samesite: "strict"
			});
			// backward compatibility
			$cookies.remove("userLogin", {
				path: "/v2/",
				secure: false,
				samesite: null
			});
			$cookies.remove("remember", {
				path: "/v2/",
				secure: false,
				samesite: null
			});
			delete appModel.userToken;
			delete appModel.account;
			delete $localStorage.rememberMeToken;
			delete $localStorage.userLogin;
			sessionStorage.removeItem("ngStorage-userToken");
			sessionStorage.removeItem("ngStorage-account");
			delete appModel.account;
			$location.path('/login');
		}

		logout();
	}
];
