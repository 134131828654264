'use strict';

import {app} from '../../app';
import {IHttpResponse, IHttpService, ILogService, ITimeoutService} from "angular";
import {AbstractComponent} from "../AbstractComponent/AbstractComponent";
import {IAccountAutomaticBlocks, IAppModel, IBlock, ICalendar, IDeliveryStatus, IDeliveryType, ILastDelivery, IRootScope, ITemplate, ITemplateDeliveryTypes, ITemplateLayerClips} from "../../hpc";
import * as _ from "underscore";
import * as restangular from "restangular";
import {IResponse} from "restangular";
import {TrackerService} from "../../services/TrackerService";

export enum playStatus {
    PLAY,
    LOAD,
    STOP
}


class Calendar implements ICalendar {
    id: number;
    version: number;
    errorMessage: string;
    begin: number;
    lastChanged: number;
    deliveryTypes: IDeliveryType[];
    deliveryStatus: IDeliveryStatus;
}


export class EditAnnouncement extends AbstractComponent {

    public static $inject = ['$log', '$rootScope', '$scope', '$sessionStorage', 'Restangular', '$timeout', 'audio', '$http', 'tracker', '$moment', 'properties','$location'];

    constructor(
        private $log: ILogService,
        private $rs: IRootScope,
        private $s,
        public appModel: IAppModel,
        private Rest: restangular.IService,
        private $timeout: ITimeoutService,
        private audio,
        private $http: IHttpService,
        private tracker: TrackerService,
        private $moment,
        private properties,
        private $location) {
        super();
    }

    public lastDelivery: ILastDelivery;
    public announcements;
    public template: ITemplate;
    public playStatus = playStatus.PLAY;
    public begin: Date;
    public end: Date;
    public activateNow: boolean = true;
    public noEnd: boolean = true;
    public loading: boolean = true;
    public fullDaySchedule: boolean;
    public audioSupported;
    public updateEndDate;
    public showBegin: boolean = false;
    public showEnd: boolean = false;
    public textLayer;
    public deliveryTypes;
    public templateText: string;
    public currentList: any;
    public currentClip: any;
    public fileUrl: string;
    public renderComplete: boolean = false;


    $onInit(): void {
        this.$log.info('init Edit Announcement');

        this.template = this.appModel.currentTemplate;
        !this.appModel.suggestionLists ? this.appModel.suggestionLists = {} : null;
        this.begin = this.$moment().toDate();
        this.end = this.$moment().add(1, 'day').startOf('day').hours(20).minute(0).millisecond(0).toDate();
        this.fullDaySchedule = _.indexOf([2], this.template.templateTypeId) == -1;

        this.$s.$watch('$ctrl.begin', (newV, oldV) => {
            if (newV != oldV) {
                this.activateNow = false;
                this.showBegin = false;
            }
        });

        this.$s.$watch('$ctrl.end', (newV, oldV) => {
            if (newV !== oldV) {
                this.noEnd = false;
                // switch to end day 23:59 instead 00:00 - condition to prevent $watch loop
                if (this.fullDaySchedule && this.showEnd) {
                    this.end = this.$moment(this.end).endOf('day').toDate();
                }
                this.showEnd = false;
            }
        });

        this.$s.$on('close', (data) => {
            this.$s.currentList = null;
            this.checkIsComplete();
        });

        this.$s.$on('refresh', (data) => {
            this.currentClip.isComplete = true;
            this.checkIsComplete();
        });

        this.audioSupported = () => {
            return !!(this.audio.canPlayType && this.audio.canPlayType('audio/mpeg;').replace(/no/, ''));
        };

        // this.audio.addEventListener('error', (e) => {
        //     this.$rs.errormessage = "ERROR_PLAYING_AUDIO";
        //     this.$log.error('Playback error: ' + e.target.error.code);
        // })


        this.updateEndDate = (which: String) => {
            this.noEnd = false;
            switch (which) {
                case '30m':
                    this.end = this.$moment(this.begin).add(30, 'minute').toDate();
                    break;
                case '1h':
                    this.end = this.$moment(this.begin).add(1, 'hour').toDate();
                    break;
                case '2h':
                    this.end = this.$moment(this.begin).add(2, 'hour').toDate();
                    break;
                case '3h':
                    this.end = this.$moment(this.begin).add(3, 'hour').toDate();
                    break;
                case '4h':
                    this.end = this.$moment(this.begin).add(4, 'hour').toDate();
                    break;
                case 'eob':
                    this.end = this.$moment(this.begin).startOf('day').hours(20).minute(0).toDate();
                    break;
                case '1t':
                    this.end = this.$moment(this.begin).add(1, 'day').endOf('day').toDate();
                    break;
                case '2t':
                    this.end = this.$moment(this.begin).add(2, 'day').endOf('day').toDate();
                    break;
                case 'kw':
                    this.end = this.$moment(this.begin).add(1, 'day').endOf('isoweek').toDate();
                    break;
                case '2kw':
                    this.end = this.$moment(this.begin).add(1, 'day').add(1, 'week').endOf('isoweek').toDate();
                    break;
            }
        };

        this.announcements = this.Rest.all('announcements');
        // this.createAnnouncement();

        this.$s.$watch('$ctrl.appModel.currentTemplate', (newV, oldV) => {
            this.loading = true;
            this.audio.pause();
            this.createAnnouncement();
            this.template = this.appModel.currentTemplate;
        });

        this.audio.addEventListener('ended', () => {
            this.$s.$apply(this.playStatus = playStatus.PLAY);
        });
        this.audio.addEventListener('playing', () => {
            this.$s.$apply(this.playStatus = playStatus.STOP);
            console.log('playing');
        });
        this.audio.addEventListener('loadstart', () => {
            console.log('loadstart');
        });
        this.audio.addEventListener('canplay', (e) => {
            console.log('canplay');
        });
        this.audio.addEventListener('error', (e) => {
            console.log('error playing audio');
            this.$s.$apply(this.playStatus = playStatus.PLAY);
            this.$rs.errormessage = 'error playing audio';
        });
    }


    $onChanges(onChangesObj: angular.IOnChangesObject): void {
        super.$onChanges(onChangesObj);
    }

    /**
     * Creates the announcement from template id
     */
    private createAnnouncement() {
        this.Rest.one('templates', this.appModel.currentTemplate.id).all('announcement').post(null)
            .then(
                (data) => {
                    this.$log.debug('filename: ' + data.filename + 'id: ' + data.id + 'name: ' + data.name);
                    // this.announcement = data;
                    this.appModel.currentAnnouncement = data;
                    this.textLayer = this.appModel.currentAnnouncement.template.templateLayers[0].templateLayerClips;
                    this.deliveryTypes = this.appModel.currentAnnouncement.template.templateDeliveryTypes;
                    this.setDeliveryTypeSwitches();
                    this.createDisplaylist();
                },
                (data: IResponse) => {
                    //errors are handled in interceptor
                })
        ;
    }

    /**
     * Enable and preset availabe DeliveryType switches
     */
    private setDeliveryTypeSwitches(): void {
        for (var i = 0; i < this.deliveryTypes.length; i++) {
            var tdt: ITemplateDeliveryTypes = this.deliveryTypes[i];
            tdt.selected = false;
            tdt.disabled = false;
            switch (tdt.deliveryType.id) {
                case 1:
                    if (this.appModel.account.phoneNumber) {
                        tdt.selected = true;
                        tdt.disabled = false;
                    }
                    break;
                case 2:
                    if (this.appModel.account.mobileNumber) {
                        tdt.selected = true;
                        tdt.disabled = false;
                    }
                    break;
                case 6:
                    if (this.appModel.account.mobileNumber) {
                        tdt.selected = true;
                        tdt.disabled = false;
                    }
                    break;
                case 7:
                    tdt.selected = true;
                    tdt.disabled = false;
                    break;
            }


        }
    }

    /**
     *
     */
    private createDisplaylist() {
        var template = this.appModel.currentAnnouncement.template;
        this.templateText = "";
        var noCompleteCheck = false;
        var textLayerClips: ITemplateLayerClips[] = template.templateLayers[0].templateLayerClips;
        var accountAutomaticBlocks: IAccountAutomaticBlocks[] = this.appModel.account.accountAutomaticBlocks;
        for (var i = 0; i < textLayerClips.length; i++) {
            var textLayerClip: ITemplateLayerClips = textLayerClips[i];
            this.templateText = this.templateText + textLayerClip.name + " ";

            //process automatic Blocks
            if (textLayerClip.type == "AutomaticBlock") {
                var staticBlock: IBlock = _.find(accountAutomaticBlocks, (item) => {
                    return item.automaticBlock.id == textLayerClip.clipId
                }).staticBlock;
                textLayerClip.name = staticBlock.name;
                textLayerClip.clipId = staticBlock.id;
                textLayerClip.type = staticBlock.type;
                textLayerClip.class = "automaticBlock";
                textLayerClip.isComplete = true;
            }
            //process manual Blocks
            else if (textLayerClip.type == "ManualBlock") {
                // use class attribute to determine if first load and if yes set name as title
                textLayerClip.class ? null : textLayerClip.title = textLayerClip.name;
                textLayerClip.class = "manualBlock";
                var listId: number = textLayerClip.suggestionListId;

                // we need to update the List
                // if (!this.appModel.suggestionLists[listId]) {
                this.getListOptions(listId);
                // }

                //update Announcement Status
                //!textLayerClip.isComplete ? isComplete=false : null;
            }
            //process Digit Blocks
            else if (textLayerClip.type == "DigitBlock") {
                //use class attribut to determine if this is first load
                if (textLayerClip.name == "Mobilenumber") {
                    textLayerClip.name = this.appModel.account.mobileNumber;
                    noCompleteCheck = true;
                    this.updateDigitBlock(textLayerClip);
                } else if (textLayerClip.name == "Phonenumber") {
                    textLayerClip.name = this.appModel.account.phoneNumber;
                    noCompleteCheck = true;
                    this.updateDigitBlock(textLayerClip);
                } else {
                    textLayerClip.class ? null : textLayerClip.name = "";
                }

                textLayerClip.class = "digitBlock";

                //update Announcement Status
                textLayerClip.name == "" ? textLayerClip.isComplete = false : textLayerClip.isComplete = true;

            }
            //process Ki Blocks
            else if (textLayerClip.type == "KIBlock") {
                //use class attribut to determine if this is first load
                if(!textLayerClip.class){
                    textLayerClip.title = textLayerClip.name;
                    textLayerClip.name = "";
                }
                textLayerClip.class ? null : textLayerClip.name = "";

                textLayerClip.class = "kiBlock";

                //update Announcement Status
                textLayerClip.name == "" ? textLayerClip.isComplete = false : textLayerClip.isComplete = true;
                textLayerClip.maxLength ? null : textLayerClip.maxLength = 600;
            }


        }
        this.textLayer = textLayerClips;
        //prevent Check if Digitblock update is running as this async callback will trigger the check anyway
        if (!noCompleteCheck) {
            this.checkIsComplete();
        }
        this.loading = false;
    }

    /**
     *
     * @returns {boolean}
     */
    private checkIsComplete(): boolean {
        var textLayerClips: ITemplateLayerClips[] = this.appModel.currentAnnouncement.template.templateLayers[0].templateLayerClips;
        var isComplete: boolean = true;
        for (var i = 0; i < textLayerClips.length; i++) {
            if (textLayerClips[i].type != "StaticBlock" && textLayerClips[i].type != "Music") {
                textLayerClips[i].isComplete ? null : isComplete = false;
            }
        }
        this.$log.debug("IsComplete:" + isComplete);
        this.isComplete = isComplete;
        if (isComplete) {
            this.renderAudio();
        }
        return isComplete;
    }

    /**
     *
     * @param listId
     */
    private getListOptions(listId: number) {
        this.announcements.one('suggestionLists', listId).get()
            .then((data) => {
                    this.appModel.suggestionLists[listId] = data;
                    this.suggestionLists = this.appModel.suggestionLists;
                },
                (data) => {
                    //errors are handled in interceptor
                })
    }

    /**
     *
     */
    private renderAudio(retryCount = 0) {
        this.$rs.errormessage = null;
        this.renderComplete = false;
        this.fileUrl = this.properties.restBaseUrl + "announcements/" + this.appModel.currentAnnouncement.id.toString() + "/stream";
        this.audio.src = this.fileUrl + "?access_token=" + this.appModel.userToken;
        this.renderComplete = true;
    }




    private prelisten() {
        this.$rs.errormessage = null;
        if (this.isComplete && this.playStatus == playStatus.PLAY) {
            this.audio.play();
            this.playStatus = playStatus.LOAD;
        } else {
            this.audio.pause();
            this.audio.currentTime = 0;
            this.playStatus = playStatus.PLAY;
        }
    }

    private download(mediaType?: { type, extension }) {
        // download the audio file, return if null
        if (!this.fileUrl) {
            return;
        }

        var a = document.createElement('a');
        a.href = this.fileUrl + "?access_token=" + this.appModel.userToken;
        if(mediaType){
            a.href = a.href + '&mediaType=' + mediaType.type + "&fileName=" + this.template.name + "." + mediaType.extension;
        }
        a.target = '_blank';
        a.click();
    }


    private locationChangeEventHandle;

    /**
     *
     * @param clip
     */
    private openList(clip: ITemplateLayerClips) {
        this.currentList = this.appModel.suggestionLists[clip.suggestionListId];
        this.currentClip = clip;
        // Prevent Back Button history
        this.locationChangeEventHandle = this.$rs.$on('$locationChangeStart', (event) => {
            this.currentList = null;
            this.currentClip = null;
            event.preventDefault();
            //removes the handle after call
            this.locationChangeEventHandle();
        })
    }


    /**
     *
     * @param clip
     */
    private updateDigitBlock(clip: ITemplateLayerClips) {
        if (clip.name.length == 0) {
            clip.isComplete = false;
            return;
        }
        //prevent render of unchaged clips
        if(clip.name==clip.previousName){
            return;
        } else {
            clip.previousName = clip.name;
        }

        // prettyprint Number
        var value = clip.name;
        if (/^[0-9+]+$/.test(value)) {
            //clip.name =  value.substr(0,4) + " " + value.substr(4,3) + " " + value.substr(7,3) + " " + value.substr(10,20);}
            this.$http.get('prettyprintNumber.php', {params: {number: clip.name, lkz: this.extractLkz()}})
                .then((res: IHttpResponse<string>) => {
                    this.$log.debug('prettyprint return: ' + res.data);
                    if (/^[0-9 \n\r]+$/.test(res.data)) {
                        clip.name = res.data.replace(/(\r\n|\n|\r)/gm, "");
                    }
                    this.performUpdateDigitBlock(clip);
                }).catch((error: IResponse) => {
                this.$log.error("Error PrettyPrinting Number");
                this.performUpdateDigitBlock(clip);
            })
        } else {
            this.performUpdateDigitBlock(clip);
        }
    }

    public updateKiBlock(clip: ITemplateLayerClips) {
        if (clip.name.length == 0) {
            clip.isComplete = false;
            return;
        }
        //prevent render of unchaged clips
        if(clip.name==clip.previousName){
            return;
        } else {
            clip.previousName = clip.name;
        }
        this.performUpdateKiBlock(clip);
    }


    private extractLkz() {
        if (this.appModel.account.mobileNumber) {
            if (this.appModel.account.mobileNumber.substr(0, 1) === "1") {
                return "1";
            } else {
                return this.appModel.account.mobileNumber.substr(0, 2);
            }
        }
        return null;
    }

    //we need to Chain request not to run in concurency update situations with announcement version.
    private requestChain = [];
    private requestPending = false;

    /**
     *
     * @param clip
     */
    private performUpdateDigitBlock(clip: ITemplateLayerClips) {
        if (this.requestPending) {
            this.requestChain.push(clip);
            return;
        }
        this.requestPending = true;
        clip.isComplete = true;
        this.announcements.one(this.appModel.currentAnnouncement.id.toString())
            .one('digitBlocks', clip.templateLayerClipId)
            .post(clip.name, {}, {
                'announcementVersion': this.appModel.currentAnnouncement.version
            })
            .then((data) => {
                this.appModel.currentAnnouncement.version = data.version;
                this.$log.log('Digit Block gespeichert:' + clip.templateLayerClipId + "->" + clip.name);
                this.requestPending = false;
                this.nextRequest();
            }, (data: IResponse) => {
                //errors are handled in interceptor
            })
    }


    private performUpdateKiBlock(clip: ITemplateLayerClips){
        if (this.requestPending) {
            this.requestChain.push(clip);
            return;
        }
        this.requestPending = true;
        clip.isComplete = true;
        this.announcements.one(this.appModel.currentAnnouncement.id.toString())
        .one('kiBlocks', clip.templateLayerClipId)
        .post(null, clip.name, {
            'announcementVersion': this.appModel.currentAnnouncement.version
        })
        .then((data) => {
            this.appModel.currentAnnouncement.version = data.version;
            this.$log.log('Ki Block gespeichert:' + clip.templateLayerClipId + "->" + clip.name);
            this.requestPending = false;
            this.nextRequest();
        }, (data: IResponse) => {
            //errors are handled in interceptor
        })
    }

    private nextRequest(){
        if (this.requestChain.length > 0) {
            let clip = this.requestChain.shift();
            if(clip.type == "DigitBlock") {
                this.performUpdateDigitBlock(clip);
            } else if (clip.type == "KIBlock") {
                this.performUpdateKiBlock(clip);
            }
        } else {
            this.checkIsComplete()
        }
    }

    public updateManualBlock(listItem) {
        this.currentClip.isComplete = false;
        this.$rs.showLoading = true;
        this.Rest.one('announcements', this.appModel.currentAnnouncement.id)
            .one('manualBlocks', this.currentClip.templateLayerClipId)
            .post(listItem.id, {}, {'announcementVersion': this.appModel.currentAnnouncement.version})
            .then((data) => {
                this.$log.debug('Manual Block gespeichert:' + this.currentClip.templateLayerClipId);
                this.appModel.currentAnnouncement.version = data.version;
                this.currentClip.isComplete = true;
                this.checkIsComplete();
            }, (data) => {
                this.$log.error('Error replace manualBlock');
                this.$rs.errormessage = 'Error replacing Block'
            });

        this.currentClip.name = listItem.name;
        this.currentClip.clipId = listItem.id;

        this.currentList = null;
        this.locationChangeEventHandle();
    }

    /**
     *
     */
    public activateAnnouncement() {
        if (this.appModel.demoMode) {
            this.$rs.errormessage = "ERROR_NO_ACTIVATION_IN_DEMO";
            return;
        }
        if (!this.isComplete) {
            this.$rs.errormessage = "Bitte vervollständigen Sie zunächst die Ansage";
            this.$log.warn('Announcement incomplete.');
            return;
        }
        if (!this.noEnd && this.begin && this.end < this.begin) {
            this.$rs.errormessage = 'ERROR_ENDDATE_BEFORE_BEGINDATE';
            return;
        }
        this.$rs.errormessage = "";
        //enable Loading
        this.$rs.showLoading = true;
        this.tracker.trackEvent('Announcement', 'Activation', this.appModel.currentAnnouncement.name);
        let calendar = this.createCalendar(this.activateNow ? null : this.begin);

        //id 4 is download
        if (calendar.deliveryTypes.find(type => type.id === 4)) {
            this.download();
        }

        this.announcements.one(this.appModel.currentAnnouncement.id.toString()).post('calendars', calendar, {'announcementVersion': this.appModel.currentAnnouncement.version})
            .then((data) => {
                //this.appModel.currentAnnouncement.version = data.version;
                this.appModel.currentAnnouncement = null;
                this.appModel.deliveryPending = true;
                this.$log.info('Announcement aktiviert');
                if (!this.noEnd) {
                    this.createFollowUpAnnouncement();
                } else {
                    this.$rs.$back();
                }

            }, (res: IResponse) => {
                this.$log.error('Error Activating Announcement');
            })
    }

    /**
     *
     */
    private createFollowUpAnnouncement() {
        this.$rs.showLoading = true;
        var followUpAnnouncement: ILastDelivery = this.findFollowUpAnnouncement();
        this.appModel.lastDelivery = null; // switching on loading
        if (!followUpAnnouncement) {
            this.$rs.showLoading = false;
            this.$rs.errormessage = "WARNING_NO_FOLLOW_UP_FOUND";
            return;
        }
        this.tracker.trackEvent('Announcement', 'followUpActivation', followUpAnnouncement.name);
        this.announcements.one(followUpAnnouncement.id.toString())
            .post('calendars', this.createCalendar(this.end), {
                'announcementVersion': followUpAnnouncement.version
            })
            .then((data) => {
                this.$log.info('Follow Up Annoncement created');
                this.$rs.$back();
            }, (res: IResponse) => {
                this.$log.error('Error creating Followup Announcement');
            })
    }

    /**
     *
     * @returns {ILastDelivery}
     */
    private findFollowUpAnnouncement() {
        if (!this.appModel || !this.appModel.lastDelivery) {
            return null;
        }
        var followUpAnnouncement: ILastDelivery = null;
        this.checkDeliveryTypesValidForFollowUp(this.appModel.lastDelivery.calendar?.deliveryTypes) ? followUpAnnouncement = this.appModel.lastDelivery : null;
        var maxBegin: number = 0;
        _.each(this.appModel.announcements, (announcement: ILastDelivery) => {
            if (announcement.calendar.begin > maxBegin && announcement.calendar.begin < this.begin.getTime() && this.checkDeliveryTypesValidForFollowUp(announcement.calendar.deliveryTypes)) {
                followUpAnnouncement = announcement;
                maxBegin = announcement.calendar.begin;
            }
        });
        return followUpAnnouncement;
    }

    /**
     *
     * @param available
     * @returns {boolean}
     */
    private checkDeliveryTypesValidForFollowUp(available: IDeliveryType[]): boolean {
        var requestedIds = [];
        var availableIds = [];
        _.each(this.deliveryTypes, (tdt: ITemplateDeliveryTypes) => {
            if (tdt.selected) {
                requestedIds.push(tdt.deliveryType.id)
            }
        });
        _.each(available, (dt: IDeliveryType) => {
            availableIds.push(dt.id);
        });

        return _.intersection(requestedIds, availableIds).length === requestedIds.length;

    }

    /**
     *
     * @param date
     * @returns {ICalendar}
     */
    private createCalendar(date: Date) {
        var cal: ICalendar = new Calendar;
        cal.version = 0;
        if (!date) {
            cal.begin = 0;
        } else {
            cal.begin = date.getTime();
        }

        cal.deliveryTypes = [];

        for (var i = 0; i < this.deliveryTypes.length; i++) {
            var tdt: ITemplateDeliveryTypes = this.deliveryTypes[i];
            if (tdt.selected) {
                cal.deliveryTypes.push(tdt.deliveryType);
            }
        }
        return cal;
    }

    public hasDeliveryType(id: number): boolean {
        return this.deliveryTypes.find(tdt => tdt.deliveryType.id === id) !== undefined;
    }

    public showScheduler() {
        //check if deliveryTypes array contains at least one element with id!=4
        return this.deliveryTypes?.filter(tdt => tdt.deliveryType.id != 4).length > 0;
    }


}

app.component('editAnnouncement', {
    bindings: {},
    templateUrl: 'ts/components/editAnnouncement/editAnnouncement.html',
    controller: EditAnnouncement
})