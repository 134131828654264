import {IAccount, IAppModel, IMandatorLanguage} from "../hpc";
import {ILocationService, ILogService} from 'angular';

export class LanguageSwitchService {

    public static $inject = ['$translate', '$moment', '$localStorage', 'tmhDynamicLocale', '$sessionStorage', '$log', '$location'];

    public supportedLanguages: string[] = ["de", "en", "fr", "it"];
    public currentLanguage: string;
    public currentMandatorLanguage: IMandatorLanguage;

    constructor(private $translate, private $moment, private $localStorage: any, private tmhDynamicLocale, private appModel: IAppModel, private $log: ILogService, private $location: ILocationService, private $translateProvider) {


    };

    public getAvailableLanguages() {
        return this.appModel.mandator.mandatorLanguages.map(lang => lang.language.name)
    }

    public getCurrentMandatorLanguage() {
        return this.findLanguageByCode(this.getLanguage());
    }

    public getLanguage() {
        return this.currentLanguage;
    }

    public isPersisted() {
        return !!this.$localStorage.language
    }

    public selectMandatorLanguage() {
        let ml;
        if (this.$location.search().lang) {
            ml = this.findLanguageByCode(this.$location.search().lang)
        }
        if (!ml && this.$localStorage.language) {
            this.$log.info("no language setting url, try localstorage")
            ml = this.appModel.mandator.mandatorLanguages.find(ml => ml.language.name == this.$localStorage.language);
        }
        if (!ml && navigator.language) {
            this.$log.info("no language setting in localstorage, try browser language: " + navigator.language.substring(0, 2))
            ml = this.appModel.mandator.mandatorLanguages.find(ml => ml.language.name == navigator.language.substring(0, 2))
        }
        if (!ml) {
            this.$log.info("no supported browser language found, use first available")
            ml = this.appModel.mandator.mandatorLanguages[0];
        }
        this.setLanguage(ml.language.name, false)
        this.currentMandatorLanguage = ml;
        this.$log.info("language selected: " + ml.language.name);
    }

    private findLanguageByCode(code) {
        this.$log.debug("try to find supported language: " + code)
        return this.appModel.mandator.mandatorLanguages.find(ml => ml.language.name == code);
    }


    public setLanguage(lang, persist = true) {
        if (this.supportedLanguages.indexOf(lang) == -1) {
            lang = "de";
        }
        this.tmhDynamicLocale.set(lang);
        this.currentLanguage = lang;
        persist ? this.$localStorage.language = lang : null;
        this.$translate.use(lang);
        this.$moment.lang(lang);
        return lang;
    }

    public setUserLang(account: IAccount) {
        this.supportedLanguages.forEach(lang => {
            if (lang == account.mandatorLanguage.language.name) {
                this.setLanguage(lang);
            }
        })
    }


}