import {app} from "./app";
import * as angular from "angular";
import {MandatorService} from './services/MandatorService';

app
    .config(['$routeProvider', function ($routeProvider: angular.route.IRouteProvider) {

        let mandatorResolver = ['mandatorService', function (mandatorService: MandatorService) {
            return mandatorService.getMandator()
        }]

        $routeProvider.when('/login', {
            templateUrl: 'partials/loginView.html',
            controller: 'LoginCtrl',
            resolve: {
                mandator: mandatorResolver
            }
        });
        $routeProvider.when('/forgotPassword', {templateUrl: 'partials/forgotPasswordView.html', controller: 'LoginCtrl', resolve: {mandator: mandatorResolver}});
        $routeProvider.when('/activateAccount', {templateUrl: 'partials/forgotPasswordView.html', controller: 'LoginCtrl', resolve: {mandator: mandatorResolver}});
        $routeProvider.when('/register', {templateUrl: 'partials/registerView.html', controller: 'RegisterCtrl', resolve: {mandator: mandatorResolver}});
        $routeProvider.when('/greetings', {templateUrl: 'partials/greetingListView.html', controller: 'MainCtrl'});
        $routeProvider.when('/greetingDetail', {templateUrl: 'partials/greetingDetailView.html', controller: 'DetailCtrl'});
        // $routeProvider.when('/itemList', {templateUrl: 'partials/itemListView.html', controller: 'ListCtrl'});
        $routeProvider.when('/settings', {templateUrl: 'partials/settingsView.html', controller: 'SettingsCtrl'});
        $routeProvider.when('/info', {templateUrl: 'partials/infoView.html', controller: 'InfoCtrl'});
        $routeProvider.when('/iFrame', {templateUrl: 'partials/iFrameView.html', controller: 'InfoCtrl'});
        $routeProvider.when('/feedback', {templateUrl: 'partials/feedbackView.html', controller: 'FeedbackCtrl'});
        $routeProvider.when('/logout', {template: '' ,controller: 'LogoutCtrl'});
        $routeProvider.otherwise({redirectTo: '/login'});


    }])