import {app} from "./app";
import * as de from "../i18n/de.json"
import * as en from "../i18n/en.json"
import * as fr from "../i18n/fr.json"
import * as it from "../i18n/it.json"

app
    .config(['$translateProvider', function ($translateProvider) {
        $translateProvider.translations('de', de);
        $translateProvider.translations('en', en);
        $translateProvider.translations('fr', fr);
        $translateProvider.translations('it', it);

        //$translateProvider.preferredLanguage('de');
        $translateProvider.useLoader('customLocaleLoader');
        $translateProvider.forceAsyncReload(true);
        $translateProvider.useSanitizeValueStrategy('sanitizeParameters')
    }])

    .factory('customLocaleLoader', ['$q', '$timeout', '$sessionStorage', '$rootScope', '$window', function ($q, $timeout, $sessionStorage, $rootScope, $window) {
        return function (options) {
            var deferred = $q.defer();
            //add env specific translations from config.json
            let envSpecificI18n = $window.evmConfig?.customI18n?.[options.key] ?? {};
            //wait for mandator data layouts to be loaded
            $rootScope.$watch('layouts', function (layouts, oldValue) {
                //try to extract lang specific locale data from layoutProperty Keys
                let translations = Object.assign(envSpecificI18n, layouts);
                for (let key in translations) {
                    let value = translations[key];
                    try {
                        let object = JSON.parse(value);
                        if (object instanceof Object) {
                            if (object[options.key]) {
                                translations[key] = object[options.key];
                            } else {
                                translations[key] = null;
                            }
                        }
                    } catch (e) {
                        continue;
                    }
                }
                deferred.resolve(translations);
            });
            return deferred.promise;

        }
    }])