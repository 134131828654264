import {IComponentController} from "angular";


export class AbstractComponent implements IComponentController {

    [s: string]: any;

    $doCheck(): void {
    }

    $onChanges(onChangesObj: angular.IOnChangesObject): void {
    }

    $onDestroy(): void {
    }

    $onInit(): void {
    }

    $postLink(): void {
    }

}