import {app} from "../../app";
import {CurrentAnnouncement} from "../currentAnnouncement/CurrentAnnouncement";
import {AbstractComponent} from "../AbstractComponent/AbstractComponent";
import {ILocationService, ILogService, ITimeoutService} from "angular";
import {IAppModel, IRootScope, ITemplate} from "../../hpc";
import {IResponse} from "restangular";
import * as _ from "underscore";

export class TemplateList extends AbstractComponent {

    public static $inject = ['$log', '$rootScope', '$sessionStorage', 'Restangular', '$location'];

    constructor(private $log: ILogService, private $rs: IRootScope, private appModel: IAppModel, private Rest, private $location: ILocationService) {
        super();
    }

    $onInit(): void {
        this.getTemplates();
    }

    public groupedTemplates;

    /**
     * retrieve available Templates from REST
     */
    private getTemplates() {
        this.Rest.all('templates').getList()
            .then((data: ITemplate[]) => {
                this.$log.info("success get templates: " + data.length);
                this.appModel.templates = data;
                this.appModel.groupedTemplates = _.groupBy(this.appModel.templates, 'templateTypeId');
            }, (data: IResponse) => {
                // TODO Error Handling
                this.$log.error('Rest error ' + data);
            })
    }

    public selectTemplate(template: ITemplate) {
        this.appModel.currentTemplate = template;
        this.$rs.slideLeft();
        this.$location.path("/greetingDetail")
    }
}

app.component('templateList', {
    bindings: {},
    templateUrl: 'ts/components/templateList/templateList.html',
    controller: TemplateList
})