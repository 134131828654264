'use strict';

import {AbstractComponent} from "../AbstractComponent/AbstractComponent";
import {app} from "../../app";
import {EditAnnouncement} from "./EditAnnouncement";
import * as _ from "underscore";
import {IAppModel, IBlock, IRootScope} from "../../hpc";
import {ILogService} from "angular";


export class OptionList extends AbstractComponent {

    public groupedItemList;
    public list;
    public clip;

    public static $inject = ['$log', '$rootScope', '$sessionStorage', 'Restangular'];

    constructor(
        private $log: ILogService,
        private $rs: IRootScope,
        public appModel: IAppModel,
        private Rest) {
        super();
    }

    $onInit(): void {
        super.$onInit();
    }


    $onChanges(onChangesObj: angular.IOnChangesObject): void {
        this.groupedItemList = _.groupBy(this.list, 'x');
    }


    $doCheck(): void {
        super.$doCheck();
    }

    public selectItem(listItem: IBlock) {
        this.onUpdate({item: listItem});
    }


}

app.component('optionList', {
    bindings: {
        list: '<',
        clip: '<',
        onUpdate: '&'
    },
    templateUrl: 'ts/components/editAnnouncement/optionList.html',
    controller: OptionList
})