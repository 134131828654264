import {IAppModel, IBaseScope, IRootScope} from "../hpc";
import * as restangular from "restangular";
import * as angular from "angular";

/**
 * Created by mjaeger on 01.06.16.
 */

interface IFeedbackScope extends IBaseScope {
    sendSupportMessage: any;
    subject: string;
    mailmessage: string;
    form: any;
    formSendSuccess: boolean;
}

export let FeedbackCtrl = [
    '$scope',
    '$rootScope',
    '$sessionStorage',
    'Restangular',
    '$log',
    '$translate',
    function ($s: IFeedbackScope,
              $rs: IRootScope,
              appModel: IAppModel,
              Rest,
              $log: angular.ILogService,
              $translate: angular.translate.ITranslateService) {

        $s.subject;
        $s.mailmessage;
        $s.sendSupportMessage = sendSupportMessage;

        var account: any = Rest.one('accounts/me', null);

        function sendSupportMessage() {
            if (appModel.demoMode) {
                $rs.errormessage = "ERROR_NO_CHANGES_IN_DEMO";
                return;
            }
            var message = "\n\n" + $s.subject + "\n\n" + $s.mailmessage;
            account.customPOST(message, "informAdmin", null, {'Content-Type': 'text/plain; charset=UTF-8'})
                .then(function (res: restangular.IResponse) {
                    $s.message = 'INFO_MESSAGE_SENT_SUCCESS';
                    $s.formSendSuccess = true;
                    $log.debug('Information Message sent (encoded)');
                }, function (res: restangular.IResponse) {
                    //exception
                })
        }
    }]
