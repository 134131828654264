/**
 * Created with IntelliJ IDEA.
 * User: mjaeger
 * Date: 10.12.13
 * Time: 18:10
 * To change this template use File | Settings | File Templates.
 */


'use strict';


import {app, IAppModel, IBaseScope, ICalendar, ILastDelivery, IRootScope, ITemplate} from "../hpc";
import * as _ from "underscore";
import * as restangular from "restangular";

interface IMainScope extends IBaseScope {
    templates: ITemplate[];
    selectTemplate: any;
    templateGroup: number;
    groupedTemplates: any;
    appModel: IAppModel;

    lastName: string;
    lastTime: number;
    lastStatus: string;
    lastStatusId: number;
    lastType: number;

    removeCalendar: (announcement: ILastDelivery) => void;

}


export let MainCtrl = [
    '$scope',
    '$rootScope',
    '$sessionStorage',
    'Restangular',
    '$location',
    '$timeout',
    '$log',
    function ($s: IMainScope,
              $rs: IRootScope,
              appModel: IAppModel,
              Rest,
              $location: ng.ILocationService,
              $timeout,
              $log: angular.ILogService) {

        $s.appModel = appModel;

        var timer = null;
        window.scrollTo(0, 0);

        if (appModel.templates) {
            $s.templates = appModel.templates;
            $s.groupedTemplates = _.groupBy($s.templates, 'templateTypeId');
        }

        // getTemplates();


        /**
         * retrieve available Templates from REST
         */
        function getTemplates() {
            Rest.all('templates').getList()
                .then(function (data: ITemplate[]) {
                    $log.info("success get templates: " + data.length);
                    appModel.templates = data;
                    $s.templates = data;
                    $s.groupedTemplates = _.groupBy($s.templates, 'templateTypeId');
                }, function (data: restangular.IResponse) {
                    // TODO Error Handling
                    $log.error('Rest error ' + data);
                })
        }

        /**
         * select a template and switch to detail view
         *
         * @param template
         */
        $s.selectTemplate = function (template: ITemplate) {
            appModel.currentTemplate = template;
            $rs.slide = "slide-left";
            $location.path("/greetingDetail")
        }

    }];