import {IAccount, IAppModel, IBaseScope, ILastDelivery, IMandator, IProperties, IRegisterAccount, IRootScope} from "../hpc";
import * as _ from "underscore";
import * as restangular from "restangular";
import {LanguageSwitchService} from "../services/LanguageSwitchService";
import {IHttpService} from "angular";


/**
 * Created with IntelliJ IDEA.
 * User: mjaeger
 * Date: 10.12.13
 * Time: 18:20
 * To change this template use File | Settings | File Templates.
 */


interface ISettingsScope extends IBaseScope {
    account: IAccount;
    layouts: any;
    mandator: IMandator;
    hasDeliveryType: (string) => boolean;
    sendVerificationSMS: () => void;
    update: () => void;
    form: any;
    sendButtonClicked: boolean;
    pinRequired: () => boolean;
    setLanguage: (string) => void;
    language: string;
    lastDelivery: ILastDelivery;
    getPinHelp;
    smsSent: number;
    reformatMobileNumber: any;
    wrongActivationCode: boolean;

}

export let SettingsCtrl = [
    '$scope',
    '$rootScope',
    '$sessionStorage',
    'properties',
    '$location',
    'Restangular',
    '$http',
    '$log',
    '$translate',
    'languageSwitch',
    function ($s: ISettingsScope,
              $rs: IRootScope,
              appModel: IAppModel,
              properties: IProperties,
              $location,
              Rest,
              $http: IHttpService,
              $log: angular.ILogService,
              $translate,
              languageSwitch: LanguageSwitchService) {

        // prepare scope
        var account: any = Rest.one('accounts', appModel.account.id);
        appModel.account = _.extend(account, appModel.account);
        $s.lastDelivery = appModel.lastDelivery;

        //we do a copy to be able to revert the form on Rest fault
        $s.account = Rest.copy(appModel.account);
        $s.layouts = appModel.layouts;
        $s.mandator = appModel.mandator;
        $s.sendVerificationSMS = sendVerificationSMS;
        $s.update = update;
        $s.smsSent = 0;

        $s.$watch('account', function (oldValue: IRegisterAccount, newValue: IRegisterAccount) {
            update()
        }, true)

        //$s.language = languageSwitch.getLanguage();
        var blockUpdates = false;
        getAccountData();

        if (appModel.demoMode) {
            $rs.errormessage = "ERROR_NO_CHANGES_IN_DEMO";
        }

        function getAccountData() {
            blockUpdates = true;
            $s.account.get()
                .then(function (data) {
                    $log.info('Account retrieved');
                    blockUpdates = false;
                    appModel.account = data;
                    $s.account = Rest.copy(appModel.account);
                }, function (res: restangular.IResponse) {
                    //do not display error for compatibility with core <3.2.9
                    $log.warn('get Account failed. Old Version of Core? (min 3.2.9)');
                    blockUpdates = false;
                    $rs.errormessage = null;

                })
        }

        /**
         * perform update with REST
         */
        function update() {
            $s.form.$setSubmitted();
            $rs.errormessage = null;
            if ($s.form.$invalid) {
                $rs.errormessage = 'ERROR_CHECK_FORM';
                return false;
            }
            if (appModel.demoMode) {
                $rs.errormessage = "Die Änderungen werden im Demomodus nicht übernommen!";
                return false;
            }
            if (blockUpdates || !$s.form.$dirty) {
                return false;
            }
            $s.account.mobileNumber = $s.account.mobileNumber?.replace("+", "");
            //$rs.showLoading = true;
            $s.account.put()
                .then(function (data: any) {
                    $log.info('Account saved: ' + data);
                    appModel.account = data;
                    languageSwitch.setUserLang(appModel.account);
                    $s.account = Rest.copy(appModel.account);
                    $s.form.$setPristine();
                    appModel.currentTemplate = null;
                }, function (res: restangular.IResponse) {
                    //errors are handled in interceptor
                    switch (res.data.type) {
                        case 'USER_MOBILENUMBER_ACTIVATION_CODE_WRONG':
                            $s.form.activationCode.$setValidity('SERVER_ERROR', false);
                            //remove errormessage set by interceptor
                            $rs.errormessage = null;
                            $s.wrongActivationCode = true;
                            return;
                        case 'USER_ACCOUNT_NO_MOBILNUMBER_PHONENUMBER':
                            //TODO do not set validity if no provider is set.
                            if($s.form.mandatorProvider){
                                $s.form.mobileNumber?.$setValidity('SERVER_ERROR', false)
                            }
                            if($s.hasDeliveryType("Mailbox")){
                                $s.form.phoneNumber?.$setValidity('SERVER_ERROR', false)
                            }
                            break;
                    }
                });
        }

        /**
         * send verification SMS
         */
        function sendVerificationSMS() {
            $s.account.activationCode = null;
            $s.sendButtonClicked = true;
            $s.wrongActivationCode = false;
            var accounts = Rest.one("accounts/" + appModel.account.id);
            accounts.customPOST(null, 'sendMobileNumberVerificationSMS', {
                msisdn: $s.account.mobileNumber,
                mandatorLanguageId: $s.account.mandatorLanguage.id,
                mandatorProviderId: $s.account.mandatorProvider.id
            })
                .then(function (res: restangular.IResponse) {
                    $log.debug('VerificationSMS sent');
                    $s.smsSent += 1;
                }, function (res: restangular.IResponse) {
                    //errors are handled in interceptor
                })
        }


        /**
         * Helper to check if PIN is required for this ProviderId as this info is not reliable on REST
         * Currently IP-Upload is available for telekom (1) and telefonica (4)
         *
         * @returns {boolean}
         */
        $s.pinRequired = function () {
            return $s.account.mandatorProvider.provider.pinRequired;
        }

        $s.getPinHelp = function () {
            let key = "HELP_HANDY_DATEN_PIN_" + $s.account.mandatorProvider.provider.id;
            if (isKeyAvailable(key)) {
                return $translate.instant(key);
            } else {
                return $translate.instant("HELP_HANDY_DATEN_PIN");
            }
        }

        $s.hasDeliveryType = function (typeName) {
            return appModel.mandator.deliveryTypes.filter(dt => dt.name == typeName).length > 0;
        }

        $s.reformatMobileNumber = function (event) {
            if ($s.account.mandatorProvider.provider.countryCode && event.target.value.indexOf($s.account.mandatorProvider.provider.countryCode) != 0) {
                if (event.target.value.indexOf("0") == 0) {
                    $s.account.mobileNumber = $s.account.mandatorProvider.provider.countryCode + event.target.value.substr(1);
                } else {
                    $s.account.mobileNumber = $s.account.mandatorProvider.provider.countryCode + event.target.value;
                }
            }
        }

        function isKeyAvailable(key) {
            return $translate.instant(key) !== key
        }


    }];