import {IAppModel, IBaseScope, IRootScope} from "../hpc";
import * as angular from "angular";
import {IWindowService} from "angular";
import * as restangular from "restangular";
import {TrackerService} from "../services/TrackerService";
import {StyleManagerService} from "../services/StyleManagerService";
import {LanguageSwitchService} from "../services/LanguageSwitchService";
import * as _ from 'underscore';

/**
 * Created with IntelliJ IDEA.
 * User: mjaeger
 * Date: 10.12.13
 * Time: 18:10
 */


interface ILoginScope extends IBaseScope {
    doLogin: any;
    getOauthLoginUri: any;
    checkLogin: any;
    logout: any;
    errormessage: string;
    getMandator: any;
    baseUrl: string;
    mandator: any;
    styles: any;
    layouts: any;
    loginError: string;
    login: string;
    remember: boolean;
    hideRemeber: boolean;
    getLoginImagePath: () => string;
    languageSwitch: LanguageSwitchService;

    password: string;
    doForgotPassword: () => void;
    setOrResetPassword: () => void;
    prt: string;
    newPasswort: string;
    newPasswortRepeat: string;
    sendSuccess: boolean;
    appModel: IAppModel;

}

export let LoginCtrl = [
        '$scope',
        '$rootScope',
        'properties',
        'Restangular',
        '$location',
        '$sessionStorage',
        '$localStorage',
        '$log',
        '$translate',
        '$cookies',
        'languageSwitch',
        'tracker',
        'styleManager',
        '$window',
        function ($s: ILoginScope,
                  $rs: IRootScope,
                  properties,
                  Rest: restangular.IService,
                  $location: angular.ILocationService,
                  appModel: IAppModel,
                  $localStorage,
                  $log: angular.ILogService,
                  $translate,
                  $cookies,
                  languageSwitch: LanguageSwitchService,
                  tracker: TrackerService,
                  styleManager: StyleManagerService,
                  $window: IWindowService
        ) {

            /**
             * Set some defaults
             */
            let userLogin = $location.search().login || $cookies.get("userLogin") || $localStorage.userLogin || null;
            let remeberMeToken = $cookies.get("remember") || $localStorage.rememberMeToken;

            /**
             * Assign Scope Functions/Attributes
             */
            $s.checkLogin = checkLogin;
            $s.doLogin = doPasswordLogin;
            $s.getOauthLoginUri = getOauthLoginUri;
            $s.doForgotPassword = doForgotPassword;
            $s.setOrResetPassword = setOrResetPassword;
            $s.languageSwitch = languageSwitch;
            $s.styles = {};
            $s.prt = $location.search().prt || null;
            $s.newPasswort = "";
            $s.newPasswortRepeat = "";
            $s.login = userLogin;
            $s.message = appModel.message;
            $s.errormessage = $location.search().errormessage || false;
            $s.remember = appModel.appMode == 1;
            $s.hideRemeber = appModel.appMode == 1;
            $s.appModel = appModel;
            $s.sendSuccess = false;
            appModel.message = null;

            resetTokenData()

            if ($s.prt && $location.path() == '/login') {
                $location.path('/forgotPassword');
            }

            $s.getLoginImagePath = function () {
                if (appModel.layouts.loginImagePath) {
                    return appModel.layouts.loginImagePath;
                } else {
                    return '/images/' + appModel.mandatorName + '/imageLogin.jpg';
                }
            }

            /**
             * start processual functions
             */
            checkLogin();
            Rest.setDefaultHeaders({"Authorization": "Bearer " + appModel.userToken});

            /**
             *   retrieves the mandator object
             */


            /**
             * Perform Login Process
             */
            function doLogin(body) {
                appModel.message = $s.message = $s.errormessage = null;
                appModel.isAdmin = false;
                $localStorage.userLogin = $s.login;
                Rest.one("authenticate").customPOST(body, null, null)
                    .then(function (data: any) {
                            if(isAdmin(data.token.token)) {
                                appModel.isAdmin = true;
                                //redirect directly to admin interface
                                if (!$s.appModel.properties.showAdminButton && !$s.appModel.mandator?.mandatorAttributes?.showAdminButton) {
                                        if(isMobile()){
                                            $s.errormessage = "ERROR_ADMIN_LOGIN";
                                            return;
                                        }
                                        $log.info("Admin permission found -> redirecting");
                                        let origin = window.location.origin.endsWith('/') ? window.location.origin.slice(0, -1) : window.location.origin;
                                        let baseUrl = properties.adminTokenAuthBaseUrl.startsWith('/') ? properties.adminTokenAuthBaseUrl.slice(1) : properties.adminTokenAuthBaseUrl;

                                        let tokenAuthUrl = `${origin}/${baseUrl}?t=${data.token.token}`;
                                        window.location.href = tokenAuthUrl;
                                        return;
                                    }
                            }
                            onAuthenticateSuccess(data);
                        }, function (data: restangular.IResponse) {
                            $log.info("authenticate error", data);
                            //500 workaroud for Backend Bug Token Encryption error 400 not sent
                            if (data.status == 400 || data.status == 500) {
                                $s.errormessage = "ERROR_USERNAME_OR_PASSWORD_WRONG";
                                $cookies.remove("userLogin", {
                                    path: "/",
                                    secure: true,
                                    samesite: "strict"
                                });
                                $localStorage.userLogin = null;
                                $cookies.remove("remember", {
                                    path: "/",
                                    secure: true,
                                    samesite: "strict"
                                });
                                delete $localStorage.rememberMeToken;
                                delete $localStorage.userLogin;
                                sessionStorage.removeItem("ngStorage-userToken");
                                sessionStorage.removeItem("ngStorage-account");
                            } else {
                                $s.errormessage = "ERROR_UNKNOWN";
                            }
                        }
                    )
            }

            /** bunch of work to do on auth success */
            function onAuthenticateSuccess(data: any) {
                let now = new Date();
                let expireDate = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());

                $log.info("authenticate Success", data);
                var tokenWrapper = data.token;
                $localStorage.userLogin = $s.login;
                if (data.rememberMeToken) {
                    if (appModel.appMode == 1) {
                        $localStorage.rememberMeToken = data.rememberMeToken;
                    } else {
                        $cookies.put("remember", data.rememberMeToken, {expires: expireDate});
                    }
                }
                appModel.userToken = tokenWrapper.token;
                var account: any = Rest.one('accounts', 'me');
                appModel.account = _.extend(Rest.one('accounts', 'me'), data.account);
                Rest.setDefaultHeaders({"Authorization": "Bearer " + tokenWrapper.token});
                //switch language to profile language only if not set before
                if (!languageSwitch.isPersisted()) {
                    languageSwitch.setUserLang(appModel.account);
                } else if (languageSwitch.getLanguage() !== appModel.account.mandatorLanguage.language.name) {
                    appModel.account.mandatorLanguage = languageSwitch.getCurrentMandatorLanguage();
                    appModel.account.put();
                    $log.info("updated language to: " + languageSwitch.getLanguage())
                }

                $location.search('prt', null);
                $location.search('code', null);
                $location.search('login', null);
                tracker.setUserId($s.login);
                $location.path("/greetings");
            }

            /**
             * Use stored credentials if available and perform Login
             */
            function checkLogin() {
                $log.info("checking login")
                if ($location.search().demoMode) {
                    appModel.demoMode = true;
                }

                //Redirect to App if App allowed and not already running in webview
                if ($location.search().allowApp && !$location.search().app && !$location.search().demoMode) {
                    checkForAppSchema();
                }

                //allow tunelling with token
                if (!$location.search().app && $location.search().t) {
                    $log.info("found token - try to login")
                    appModel.userToken = $location.search().t;
                    if(isAdmin(appModel.userToken)) {
                        appModel.isAdmin = true;
                    }
                    Rest.setDefaultHeaders({"Authorization": "Bearer " + appModel.userToken});
                    var account: any = Rest.one('accounts', 'me');
                    account.get()
                        .then(data => {
                            appModel.account = data;
                            languageSwitch.setUserLang(appModel.account);
                            $location.search('t', null);
                            $location.path("/greetings");
                        })
                    return;
                }

                if ($location.search().login && $location.search().password) {
                    $log.info("found username/password - try to login")
                    $s.login = $location.search().login;
                    $s.password = $location.search().password;

                    let body = {
                        username: $s.login,
                        password: $s.password,
                        nameMandator: appModel.mandatorName,
                        rememberMeToken: remeberMeToken,
                        remember: $s.remember
                    }
                    doLogin(body);
                } else if (userLogin && $location.search().code) {
                    $log.info("found code - try code flow")
                    $s.login = userLogin;
                    $s.password = null;
                    let body = {
                        username: $s.login,
                        nameMandator: appModel.mandatorName,
                        rememberMeToken: remeberMeToken,
                        remember: $s.remember,
                        code: $location.search().code
                    }
                    doLogin(body);
                } else if (userLogin && remeberMeToken) {
                    $log.info("found rememberme cookie - try to login")
                    $s.login = userLogin;
                    $s.password = null;
                    let body = {
                        username: $s.login,
                        nameMandator: appModel.mandatorName,
                        rememberMeToken: remeberMeToken,
                        remember: $s.remember
                    }
                    doLogin(body);
                }
            }

            function doPasswordLogin() {
                let body = {
                    username: $s.login,
                    password: $s.password,
                    nameMandator: appModel.mandatorName,
                    rememberMeToken: remeberMeToken,
                    remember: $s.remember,
                    code: $location.search().code
                }
                doLogin(body);
            }

            function getOauthLoginUri(provider) {
                return properties.oauthBaseUrl + provider +
                    "?mandatorName=" + appModel.mandatorName +
                    "&redirect_uri=" + encodeURIComponent($location.absUrl().split('?')[0]) +
                    "&register_uri=" + encodeURIComponent($location.absUrl().split('?')[0].replace('/login', '/register'));
            }


            /**
             * Request ForgotPassword Mail
             */
            function doForgotPassword() {
                if (appModel.demoMode) {
                    $rs.errormessage = "Diese Funktion ist im Demomodus deaktiviert!";
                    return false;
                }
                $s.errormessage = $s.message = appModel.message = null;
                $localStorage.userLogin = $s.login;
                var account = Rest.one('accounts', $s.login);
                account.customPOST(null, "password/forgot", {nameMandator: appModel.mandatorName})
                    .then(function (data: any) {
                        $log.info('forgot Password Success');
                        $s.message = 'INFO_CHECK_EMAIL';
                        $s.sendSuccess = true;
                        $s.errormessage = null;
                    }, function (res: restangular.IResponse) {
                        $log.warn('forgot Password Failed');
                        if (res.data && res.data.type) {
                            $s.errormessage = res.data.type;
                        } else {
                            $s.errormessage = 'ERROR_REST_SERVICE';
                        }
                    })

            }

            /**
             * set or reset password dependand on location
             *
             */
            function setOrResetPassword() {
                if ($s.newPasswort !== $s.newPasswortRepeat) {
                    $s.errormessage = 'ERROR_PASSWORT_REPEAT_NO_MATCH';
                    return;
                }
                if ($location.path() == '/forgotPassword') {
                    doResetPasswort();
                } else {
                    doSetPassword();
                }
            }

            /**
             * Reset Password with received Token (prt)
             */
            function doResetPasswort() {
                $s.errormessage = $s.message = null;
                var account = Rest.one('accounts', $s.login);
                account.customPOST(null, 'password/reset', {
                    nameMandator: appModel.mandatorName,
                    prt: $s.prt,
                    newPassword: $s.newPasswort
                })
                    .then(function (data: any) {
                        $log.info('reset Password success');
                        $s.errormessage = null;
                        appModel.message = 'INFO_PASSWORD_RESETED';
                        $location.search('prt', null);
                        $location.path('/login');

                    }, function (res: restangular.IResponse) {
                        $log.warn('reset Password Failed');
                        if (res.data && res.data.type) {
                            $s.errormessage = res.data.type;
                        } else {
                            $s.errormessage = 'ERROR_REST_SERVICE';
                        }

                    })

            }

            /**
             * Reset Password with received Token (prt)
             */
            function doSetPassword() {
                $s.errormessage = $s.message = null;
                var account = Rest.one('accounts', $s.login);
                account.customPOST(null, 'activate', {
                    nameMandator: appModel.mandatorName,
                    prt: $s.prt,
                    newPassword: $s.newPasswort
                })
                    .then(function (data: any) {
                        $log.info('set Password success');
                        $s.errormessage = null;
                        appModel.message = 'INFO_PASSWORD_SET';
                        $location.search('prt', null);
                        $location.path('/login');

                    }, function (res: restangular.IResponse) {
                        $log.warn('set Password Failed');
                        $s.errormessage = 'USER_ACTIVATE_TOKEN_NOT_AVAILABLE';
                    })

            }

            function checkForAppSchema() {
                if (!isMobile()) {
                    //no app on desktop
                    return;
                }
                var url = $location.absUrl();
                url = 'evmapp://' + url.replace(/^https?:\/\//, '');
                $log.info('try to open app: ' + url);
                window.location.href = url;
            }

            function resetTokenData(){
                //delete old tokens first, if still present
                appModel.userToken = null;
                delete appModel.userToken;
                appModel.isAdmin = null;
                delete appModel.isAdmin;
            }

            function isMobile() {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            }

            function isAdmin(token) {
                let payload = parseJwt(token);
                let permissions = payload.PERMISSION;
                let adminPermissions = [1, 10, 20];
                let filteredPermissions = permissions.filter(permission => adminPermissions.includes(permission));
                return filteredPermissions.length > 0;
            }


            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            }
        }
    ]
;
