import {IMandatorLayoutProperty} from "../hpc";
import {IWindowService} from "angular";

export class StyleManagerService {

    public static $inject = ['$window', '$localStorage', 'properties'];

    constructor(private $window: IWindowService, private $localStorage, private properties) {
        this.addCssVariables();
    }

    private addCssVariables() {
        if (this.$localStorage.styles) {
            this.performAddCssVariables(this.$localStorage.styles)
        }
    }

    public initCssVariables(list?: IMandatorLayoutProperty[]) {
        if (list) {
            this.$localStorage.styles = this.extractStylesFromLayoutProperties(list);
        }
        this.addCssVariables();
    }

    private extractStylesFromLayoutProperties(list: IMandatorLayoutProperty[]) {
        let styles = {};
        list.forEach((item) => {
            if (item.propertyName.indexOf("Color") != -1) {
                styles['--' + item.propertyName] = '#' + item.value;
            } else if (item.propertyName.indexOf("Path") != -1) {
                styles['--' + item.propertyName] = 'url(' + this.properties.assetsBaseUrl + item.value + ')';
            } else if (item.propertyName.indexOf("customCSS") != -1) {
                this.performAddCustomCss(item.value);
            }
        })
        return styles;
    }


    private performAddCssVariables(styles) {
        /** extract Layout Properties in css Variables - new as of 2019*/
        let root = this.$window.document.documentElement;
        Object.keys(styles).forEach(key => {
            root.style.setProperty(key, styles[key]);
        })
    }

    private performAddCustomCss(css) {
        var sheet = document.createElement('style')
        sheet.innerHTML = css;
        document.body.appendChild(sheet);
    }

}