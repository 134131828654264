/**
 * Created with IntelliJ IDEA.
 * User: mjaeger
 * Date: 10.12.13
 * Time: 18:18
 * To change this template use File | Settings | File Templates.
 */


import {
    IBaseScope,
    ITemplate,
    IAnnouncement,
    ITemplateLayerClips,
    ITemplateDeliveryTypes,
    ILastDelivery,
    ICalendar,
    IDeliveryType,
    IDeliveryStatus,
    IAppModel,
    IAccountAutomaticBlocks, IBlock, app
} from "../hpc";
import {Moment} from "moment";
import * as restangular from "restangular";
import * as _ from "underscore";
import {IHttpResponse, ILocationService} from "angular";


export let DetailCtrl = [
    '$scope',
    '$sessionStorage',
    '$location',
    '$log',
    function ($s,
              appModel: IAppModel,
              $location: ILocationService,
              $log: angular.ILogService) {
        if (!appModel.currentTemplate) {
            $location.path('/myGreetings')
        }


    }];


