import {FeedbackCtrl} from './FeedbackCtrl'
import {DetailCtrl} from './GreetingDetailCtrl'
import {MainCtrl} from './MainCtrl'
import {InfoCtrl} from './InfoCtrl'
// import {ListCtrl} from './ListCtrl'
import {LoginCtrl} from './LoginCtrl'
import {NavCtrl} from './NavCtrl'
import {SettingsCtrl} from './SettingsCtrl'
import {RegisterCtrl} from './RegisterCtrl'
import {app} from '../app';
import {LogoutCtrl} from "./LogoutCtrl";


app.controller("FeedbackCtrl", FeedbackCtrl)
    .controller('DetailCtrl', DetailCtrl)
    .controller('MainCtrl', MainCtrl)
    .controller('InfoCtrl', InfoCtrl)
    // .controller('ListCtrl', ListCtrl)
    .controller('LoginCtrl', LoginCtrl)
    .controller('NavCtrl', NavCtrl)
    .controller('SettingsCtrl', SettingsCtrl)
    .controller('RegisterCtrl', RegisterCtrl)
    .controller('LogoutCtrl', LogoutCtrl)



