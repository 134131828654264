'use strict';

import {app} from '../app';

/* Filters */
app
    .filter('interpolate', ['version', function (version) {
        return function (text) {
            var a = 1;
            return String(text).replace(/\%VERSION\%/mg, version);
        }
    }])
    .filter('fDate', ['$filter', function ($filter) {
        return function (format) {
            return $filter('date')(new Date(), format);
        }
    }])
    .filter('t', ['$translate', function ($translate) {
        return function (text, fallback) {
            if (!fallback || $translate.instant(text) !== text) {
                return $translate.instant(text);
            } else {
                return $translate.instant(fallback);
            }
        }
    }])




