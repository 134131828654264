import {ILogProvider} from "angular";
import * as restangular from "restangular";
import {app} from "./app";

app
    .config(['$logProvider', function ($logProvider: ILogProvider) {
        $logProvider.debugEnabled(false);
    }])
    // compatibility for upgrade to NG 1.6
    .config(['$locationProvider', '$compileProvider', function ($locationProvider, $compileProvider) {
        $locationProvider.hashPrefix('');
        $locationProvider.html5Mode(true);
        //$locationProvider.html5Mode(true);
        // $compileProvider.preAssignBindingsEnabled(true);
    }])
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.defaults.headers.common = {'Accept': 'application/json', 'Content-Type': 'application/json'};
        $httpProvider.defaults.withCredentials = true;
    }])
    .config(['RestangularProvider', function (rp: restangular.IProvider) {
        rp.setBaseUrl('/services/rest/v2');
        rp.setDefaultHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate'
        });
    }])
    .config(['tmhDynamicLocaleProvider', function (tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern('lib/angular-locale_{{locale}}.js')
    }])
    .config(['$cookiesProvider', function ($cookiesProvider) {
        let now = new Date();
        let expireDate = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
        $cookiesProvider.defaults.path = "/";
        $cookiesProvider.defaults.secure = true;
        $cookiesProvider.defaults.samesite = "strict";
        $cookiesProvider.defaults.expireDate = expireDate;
    }])