'use strict';

import {app} from '../../app';
import {ILogService, ITimeoutService} from "angular";
import {AbstractComponent} from "../AbstractComponent/AbstractComponent";
import {IAppModel, ICalendar, ILastDelivery, IRootScope} from "../../hpc";
import * as _ from "underscore";


export class CurrentAnnouncement extends AbstractComponent {

    public static $inject = ['$log', '$rootScope', '$sessionStorage', 'Restangular', '$timeout'];


    constructor(private $log: ILogService, private $rs: IRootScope, private appModel: IAppModel, private Rest, private $timeout: ITimeoutService) {
        super();
    }

    // public lastDelivery;

    $onInit(): void {
        this.$log.info('init log info');
        //this.appModel.lastDelivery = null;
        this.getCurrentAnnouncement();
        this.getCalendars();

        //update current announcement on resume event (broadcasted by cordova wrapper app)
        this.$rs.$on('appresume', () => {
            this.$log.info('resume - reloading currentAnnouncement');
            this.appModel.lastDelivery = null;
            this.getCurrentAnnouncement();
            this.getCalendars();
        })
    }


    /**
     * get the current Announcement from REST
     */
    private getCurrentAnnouncement() {
        this.Rest.one('accounts/me', null).one('currentAnnouncement').get()
            .then((data) => {
                if (!data) {
                    this.appModel.lastDelivery = {} as ILastDelivery;
                } else {
                    this.$log.info('success get currentAnnouncement:' + data);
                    if (data.calendars[0].deliveryStatus.id == 2) {
                        this.$log.log('Delivery Pending, start Timer');
                        this.appModel.deliveryPending = true;
                        this.$timeout(() => {
                            return this.getCurrentAnnouncement()
                        }, 5000)
                    }
                    this.appModel.deliveryPending = false;
                    this.appModel.lastDelivery = data;
                    this.appModel.lastDelivery.calendar = data.calendars[0];
                }
            }, function (data) {
                // TODO Error Handling
                this.$log.error('Error get currentAnnouncement')
            })
    }

    private getCalendars() {
        this.Rest.one('accounts/me', null).one('listAnnouncements').get()
            .then((data) => {
                this.$log.info('Success listAnnouncements');
                var anncouncements = [];

                _.each(data, function (announcement: ILastDelivery) {
                    _.each(announcement.calendars, function (calendar: ICalendar) {
                        if (calendar.deliveryStatus.id == 1) {
                            var tmpAnnouncement: ILastDelivery = _.clone(announcement);
                            tmpAnnouncement.calendars = null;
                            tmpAnnouncement.calendar = calendar;
                            anncouncements.push(tmpAnnouncement);
                        }

                    })
                });
                this.appModel.announcements = anncouncements;
            }, (data) => {
                // TODO Error Handling
                this.$log.error('Error listAnnouncemets');
            })
    }

    /**
     * Remove Scheduled Calendar
     *
     * @param announcement
     */
    public removeCalendar(announcement: ILastDelivery) {
        this.$rs.showLoading = true;
        var calendar = this.Rest.one('announcements', announcement.id).one('calendars', announcement.calendar.id);
        calendar.remove({
            announcementVersion: announcement.version,
            calendarVersion: announcement.calendar.version
        })
            .then((data) => {
                this.$log.info('removed calendar success');
                this.getCalendars();
            }, (data) => {
                this.$log.error('removing calendar failed');
                this.$rs.errormessage = 'ERROR_REMOVING_ANNOUNCEMENT';
            })
    }

}

app.component('currentAnnouncement', {
    bindings: {},
    templateUrl: 'ts/components/currentAnnouncement/currentAnnouncement.html',
    controller: CurrentAnnouncement
})