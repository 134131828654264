'use strict';

import * as restangular from "restangular";
import {IAppModel, IProperties, IRootScope, WebkitWindow} from "./hpc";
import * as angular from "angular";
import {ILogService, IWindowService} from "angular";
import "angular-route";
import "angular-animate";
import "angular-sanitize";
import "angular-ui-bootstrap";
import "angular-touch";
import "angular-translate";
import "angular-cookies";
import "angular-dynamic-locale";
import "restangular";
import "ngstorage";
import "ng-onload/release/ng-onload.min";
import '../lib/datetimepicker';
import '../lib/ui-bootstrap-tpls.min';
import "angular-svg-round-progressbar";
import "angular-elastic";
//CSS
import 'normalize-css';
import '../css/ionicons.min.css'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/bootstrap-datetimepicker.min.css';
import '../css/datetimepicker.css';
import '../css/01_global.scss';
import '../css/02_navbar.scss';
import '../css/03_footer.scss';
import '../css/04_login.scss';
import '../css/05_form_elements.scss';
import '../css/06_animations.scss';
import '../css/06_alerts.scss';
import '../css/app.scss';
import '../css/99_customMandator.scss';
import {StyleManagerService} from "./services/StyleManagerService";
import {MandatorService} from './services/MandatorService';
import {LanguageSwitchService} from './services/LanguageSwitchService';

//not working - imported in index.html
// import 'moment';
// import 'angular-momentjs';


// Declare app level module which depends on filters, and services
export let app = angular.module('app', [
    'ngRoute',
    'ngAnimate',
    'ngStorage',
    'ngCookies',
    'ngSanitize',
    'restangular',
    'ui.bootstrap',
    'ngTouch',
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'angular-momentjs',
    'ui.bootstrap.datetimepicker',
    'ngOnload',
    'monospaced.elastic',
    'angular-svg-round-progress'])


    .run(['Restangular',
        '$location',
        '$rootScope',
        '$sessionStorage',
        'properties',
        '$log',
        '$window',
        '$timeout',
        '$http',
        '$templateCache',
        'styleManager',
        '$translate',
        'languageSwitch',
        'mandatorService',
        'ui_version',
        function (rp: restangular.IProvider,
                  $location: ng.ILocationService,
                  $rs: IRootScope,
                  appModel: IAppModel,
                  properties: IProperties,
                  $log: ILogService,
                  $window: IWindowService,
                  $timeout,
                  $http: ng.IHttpService,
                  $templateCache,
                  styleManager: StyleManagerService,
                  $translate,
                  languageSwitch: LanguageSwitchService,
                  mandatorService: MandatorService,
                  ui_version) {
        
            $rs.languageService = languageSwitch;
            $rs.ui_version = ui_version;
            styleManager.initCssVariables();
            rp.setErrorInterceptor(function (res: restangular.IResponse) {
                if (res.data && (res.data.type == "SECURITY_TOKEN_INVALID" || res.data.type == "SECURITY_TOKEN_OUTOFDATE")) {
                    $log.warn('Token invalid. Log in');
                    appModel.userToken = null;
                    appModel.account = null;
                    $location.path('/login');
                } else if ((res.status === 0 || res.status === 404) && res.config.url.indexOf('listAnnouncements') != -1) {
                    //ugly workaround as we do not receive the original error (404) if no schedules available
                    return true;
                } else if (res.data && res.data.type) {
                    $log.warn('Rest Warning: ' + res.data.description);
                    $rs.errormessage = res.data.type;
                } else if (res.status === 0) {
                    $log.error('Error fetching Request: Status: ' + res.status);
                    $rs.errormessage = 'ERROR_CHECK_NETWORK';
                } else {
                    $log.error('Error fetching Request: Status: ' + res.status);
                    $rs.errormessage = 'ERROR_UNKNOWN';
                    //$rs.errormessage = JSON.stringify(res);
                }
                $rs.showLoading = false;
                window.scrollTo(0, 0);
                return true;
            });

            /*bring errormessage to screen if scrolled down also on local errors not handled by interceptor*/
            $rs.$watch('errormessage', function (newValue, oldValue) {
                if (newValue && newValue != '') {
                    window.scrollTo(0, 0);
                }
            });
            appModel.appMode = $rs.appMode = $location.search().app ? 1 : appModel.appMode;

            setMode();
            $window.addEventListener('resize', function () {
                setMode();
            });

            function setMode() {
                if ($window.innerWidth >= 768) {
                    $rs.viewMode = 'desktop';
                } else {
                    $rs.viewMode = 'mobile';
                }
                $rs.$apply();
            }


            rp.addResponseInterceptor(function (data, operation, what, url, response, deferred) {
                // prevent Loading change on prelisten response
                if (url.indexOf('/preview') == -1) {
                    $rs.showLoading = false;
                }
                return data;
            })

            appModel.debugMode = $rs.debugMode = properties.debugMode;

            /**
             * Set some defaults
             */
            if (appModel.debugMode) {
                appModel.mandatorName = properties.debugMandator;
                rp.setBaseUrl(properties.debugRestBaseUrl);
                appModel.properties = {
                    baseUrl: properties.debugAssetsBaseUrl

                };
                $log.info('Running in debugMode');
            } else {
                appModel.mandatorName = $location.host().split('.')[0];
                rp.setBaseUrl(properties.restBaseUrl);
                appModel.properties = {
                    baseUrl: properties.assetsBaseUrl
                };
                $log.info('Running in normal mode')
            }

            appModel.properties = properties;

            //send messsage to native app
            $rs.sendAppMessage = function (message) {
                const webkitWindow = window as unknown as WebkitWindow;
                if (webkitWindow.webkit && webkitWindow.webkit.messageHandlers && webkitWindow.webkit.messageHandlers.cordova_iab){
                    console.log('Sending : ' + JSON.stringify(message) + ' to native app');
                    webkitWindow.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(message));
                } else {
                    console.log('No native app found, ignoring message: ' + JSON.stringify(message));
                }
            }

            // preload some views for (animation) performance
            $http.get('partials/greetingDetailView.html', {cache: true}).then(function (res) {
                $templateCache.put('partials/greetingDetailView.html', res.data);
            });
            $http.get('ts/components/editAnnouncement/editAnnouncement.html', {cache: true}).then(function (res) {
                $templateCache.put('ts/components/editAnnouncement/editAnnouncement.html', res.data);
            });
            $http.get('ts/components/editAnnouncement/optionList.html', {cache: true}).then(function (res) {
                $templateCache.put('ts/components/editAnnouncement/optionList.html', res.data);
            });

        }])







